import { useCallback } from 'react'

import { HTTPMethod } from '@repo/et-types/common/api'

import useResourceMethods from 'hooks/useResourceMethods'

import type { MagicAuthUser } from '@repo/et-types'
import type { ApiQueryParams, ApiResponse } from '@repo/et-types/common/api'
import type { UseResource } from 'hooks/useResource/types'

export const MAGIC_AUTH_USER_PATH_NAME = '/v2/magic_auth'
export const MAGIC_AUTH_USER_PUBLIC_PATH_NAME = '/v2/public/magic_auth'
export const MAGIC_AUTH_USER_RESOURCE_NAME = 'magic_auth'

export type EmailLoginLinkParams = {
  email: string
  redirect_url: string
  booking_uuid: string
}

interface UseMagicAuthUserResource extends UseResource<MagicAuthUser> {
  getCurrentMagicAuthUser: (queryParams?: ApiQueryParams) => Promise<ApiResponse<MagicAuthUser>>
  emailLoginLink: (data: EmailLoginLinkParams, queryParams?: ApiQueryParams) => Promise<undefined>
}

const useMagicAuthUserResource = (): UseMagicAuthUserResource => {
  const methods = useResourceMethods<MagicAuthUser>({
    path: MAGIC_AUTH_USER_PATH_NAME,
    name: MAGIC_AUTH_USER_RESOURCE_NAME
  })

  const getCurrentMagicAuthUser = useCallback(
    async (queryParams?: ApiQueryParams) =>
      methods.request<undefined, ApiResponse<MagicAuthUser>>({
        method: HTTPMethod.GET,
        url: `${MAGIC_AUTH_USER_PUBLIC_PATH_NAME}/current_magic_auth_user`,
        params: queryParams
      }),
    [methods]
  )

  const emailLoginLink = useCallback(
    async (data: EmailLoginLinkParams, queryParams?: ApiQueryParams) =>
      methods.request<EmailLoginLinkParams, undefined>({
        method: HTTPMethod.POST,
        url: `${MAGIC_AUTH_USER_PUBLIC_PATH_NAME}/email_login_link`,
        params: queryParams,
        data: data
      }),
    [methods]
  )

  return {
    ...methods,
    defaultInclude: [],
    getCurrentMagicAuthUser,
    emailLoginLink,
    pathName: MAGIC_AUTH_USER_PATH_NAME,
    queryKey: ['magic_auth_user'],
    resourceName: MAGIC_AUTH_USER_RESOURCE_NAME,
    toFormData: (data) => ({ ...data })
  }
}

export default useMagicAuthUserResource
