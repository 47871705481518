import axios from 'axios'
import Jsona from 'jsona'
import Qs from 'qs'

import { ApiLocalStorageKey } from 'enums/api'

import type { AxiosRequestHeaders } from 'axios'

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL
})

api.defaults.withCredentials = true

api.interceptors.response.use((response) => {
  try {
    const dataFormatter = new Jsona()

    response.data.normalizedData = dataFormatter.deserialize(response.data)
  } catch {
    // do nothing
  }

  return response
})

api.interceptors.request.use((config) => {
  config.paramsSerializer = (params) =>
    Qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false
    })
  config.params = { ...config.params, is_private: true }

  const reviewApiUrl = localStorage.getItem(ApiLocalStorageKey.REVIEW_APP_API_URL)

  if (reviewApiUrl) {
    config.baseURL = reviewApiUrl
  }

  return config
})

export const addAccessTokenInterceptor = (token: string) => {
  api.interceptors.request.use(async (config) => {
    config.headers ||= {} as AxiosRequestHeaders
    config.headers['X-Api-Org'] = `${token}`
    return config
  })
}

export default api
