import { useMutation } from '@tanstack/react-query'

import { useMagicAuthUserResource } from 'features/magicAuthUser/hooks'

import type { EmailLoginLinkParams } from './useMagicAuthUserResource'
import type { UseMutationResult } from '@tanstack/react-query'

type EmailLoginLinkResult = UseMutationResult<void, unknown, EmailLoginLinkParams, unknown>

interface UseMagicAuthUserActionsReturn {
  emailLoginLinkMutation: EmailLoginLinkResult
}

const useMagicAuthUserActions = (): UseMagicAuthUserActionsReturn => {
  const magicAuthUserResource = useMagicAuthUserResource()

  const emailLoginLinkMutation = useMutation({
    mutationFn: async (params: EmailLoginLinkParams) => {
      await magicAuthUserResource.emailLoginLink(params)
    }
  })

  return { emailLoginLinkMutation }
}

export default useMagicAuthUserActions
