'use client'

import { LoadingButton } from '@mui/lab'
import { Box, Stack, Typography } from '@mui/material'

import { useSearchParams } from 'next/navigation'

import { FormTextField } from '@eventtemple/eventtemple-ui'
import { yupResolver } from '@hookform/resolvers/yup'

import { useTranslations } from 'next-intl'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { ApiLocalStorageKey } from 'enums/api'
import { ImageSrc } from 'enums/images'
import { useMagicAuthUserActions } from 'features/magicAuthUser/hooks'

import type { AxiosError } from 'axios'

interface LoginFormData {
  email: string
  api_url: string
}

interface LoginFormProps {
  onSuccess: () => void
  title?: string
  emailHelperText?: string
  submitBtnLabel?: string
  bookingUuid?: string
}

const LoginForm = ({
  onSuccess,
  title,
  emailHelperText,
  submitBtnLabel,
  bookingUuid
}: LoginFormProps): JSX.Element => {
  const queryParams = useSearchParams()
  const t = useTranslations()
  const { emailLoginLinkMutation } = useMagicAuthUserActions()

  const isPreviewApp = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'

  const schema = yup.object().shape({
    email: yup.string().email(t('invalidEmail')).required(t('isRequired'))
  })

  const methods = useForm<LoginFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      api_url: process.env.NEXT_PUBLIC_API_URL || ''
    }
  })

  const onSubmit = async (data: LoginFormData) => {
    localStorage.setItem(ApiLocalStorageKey.REVIEW_APP_API_URL, data.api_url)

    emailLoginLinkMutation.mutate(
      {
        email: data.email,
        booking_uuid: bookingUuid || '',
        redirect_url: queryParams.get('redirect_url') || '/'
      },
      { onSuccess, onError: handleOnError }
    )
  }

  const handleOnError = (e: unknown) => {
    const error = e as AxiosError<{ errors: [{ detail: string }] }>
    let message = error?.response?.data?.errors?.[0]?.detail

    if (message?.toLowerCase() === 'email not found') {
      message = `${t('emailNotFound')}. ${t('ifYouThinkYouShouldHaveAccess')}`
    }

    methods.setError('email', { message })
  }

  return (
    <Stack alignItems="center" rowGap={1}>
      <Box component="img" height={50} src={ImageSrc.IC_PASSWORD} alt="password" loading="lazy" />
      <Typography variant="h4">
        {title ?? `${t('signIn')} ${t('to').toLowerCase()} ${t('guestPortal')}`}
      </Typography>
      <FormProvider {...methods}>
        <Box noValidate component="form" onSubmit={methods.handleSubmit(onSubmit)} width="100%">
          <Stack>
            <FormTextField
              margin="normal"
              label={`${t('email')} ${t('address', { count: 1 })}`}
              name="email"
              type="text"
              required
              helperText={emailHelperText}
              fullWidth
            />

            {isPreviewApp && (
              <FormTextField margin="normal" label="API URL" name="api_url" required fullWidth />
            )}

            <LoadingButton
              data-testid="button-login"
              type="submit"
              loading={false}
              fullWidth
              variant="contained"
              sx={{ my: 2 }}>
              {submitBtnLabel ?? t('login')}
            </LoadingButton>
          </Stack>
        </Box>
      </FormProvider>
    </Stack>
  )
}

export default LoginForm
