import { useQuery } from '@tanstack/react-query'

import { useMagicAuthUserResource } from 'features/magicAuthUser/hooks'

import type { MagicAuthUser } from '@repo/et-types'
import type { ApiResponse, QueryParams, QueryResponse } from '@repo/et-types/common/api'
import type { AxiosError } from 'axios'

const useCurrentMagicAuthUser = ({
  queryParams,
  options,
  enabled
}: QueryParams<MagicAuthUser> = {}): QueryResponse<MagicAuthUser> => {
  const magicAuthUserResource = useMagicAuthUserResource()

  const { data, isLoading, isError, refetch } = useQuery<ApiResponse<MagicAuthUser>, Error>({
    queryKey: ['current_magic_auth_user', queryParams],
    queryFn: () => magicAuthUserResource.getCurrentMagicAuthUser(queryParams),
    enabled: enabled ?? true,
    onSuccess: (response) => options?.onSuccess?.(response.normalizedData),
    onError: (error) => options?.onError?.(error as AxiosError),
    retry: 1
  })

  return {
    data: data?.normalizedData as MagicAuthUser,
    isLoading,
    isError,
    refetch,
    options: []
  }
}

export default useCurrentMagicAuthUser
